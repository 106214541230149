import { instance } from '@/utils/http.js'

export const getCityTree = function () {
  return instance({
    url: '/api/v2/public/get_city_tree',
    method: 'get'
  })
}

export const getDefaultCityByDis = function (params) {
  return instance({
    url: '/api/v2/student/district_city',
    method: 'get',
    params
  })
}
