<template>
  <div>
    <el-dialog :title="text + '试卷地区'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               v-if="dialogVisible"
               ref="formRef"
               label-width="80px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="地区名称"
                          prop="paper_district_name">
              <el-input placeholder="请输入"
                        v-model="form.paper_district_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="城市"
                          prop="city_id">
              <div class="zhishidian">
                <el-cascader v-model="form.city_ids"
                             :options="options"
                             :props="props"
                             style="width:100%"></el-cascader>
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="地区描述">
              <el-input placeholder="请输入"
                        v-model="form.paper_district_desc"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="所有科目">
              <el-select style="width:100%"
                         v-model="form.paper_subjects"
                         clearable
                         multiple
                         placeholder="请选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCityTree } from '@/api/city.js'

export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      subjectList: [],
      disList: [],
      gradeList: [],
      options: [],
      props: { multiple: true, value: 'city_id', label: 'name', children: 'child' },
      rules: {
        paper_district_name: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
      },
      text: '',
      childList: []
    }
  },
  mounted () {
    this.getCityTree()
  },
  methods: {
    async getCityTree () {
      const { data } = await getCityTree()
      this.options = data
      this.options.forEach(item => {
        if (item.child) {
          this.childList.push(...item.child)
        }
      });
    },
    getCityIds (form) {
      form.city_ids = form.city_ids.map(item => this.searchParent(item))
      this.form = form
    },
    searchParent (id) {
      var item = this.childList.find(item => item.city_id == id)
      return [item.parent_id, item.city_id]
    },
    getSubjectList () {
      this.$http({
        url: '/api/v2/public/paper_subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
      })
    },
    handleClose () {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.paper_subjects = form.paper_subjects.join(',')
        form.city_ids = form.city_ids.map(item => item[1])
        this.$http({
          url: '/api/v2/paper/district_add',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.handleClose()
          this.$parent.search()
        })
      })
    },
    getSubjectList () {
      this.$http({
        url: '/api/v2/public/paper_subject',
        method: 'get'
      }).then(res => {
        console.log('res', res)
        this.subjectList = res.data.list
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}

.zhishidian {
  height: 33px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  border-radius: 4px;
  ::v-deep .el-cascader__tags .el-tag:not(.is-hit) {
    height: 33px;
  }
  ::v-deep .el-cascader__tags .el-tag {
    background-color: #ffffff;
  }
  ::v-deep .el-cascader__tags {
    height: 33px;
  }
  ::v-deep .el-icon-close {
    display: none;
  }
}
</style>